<template>
  <div id="my-account">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Change Password</div>
        <div class="actions-buttons"></div>
      </el-header>
    </portal>
    <div class="table-container">
      <div class="view-info-account">
        <div class="my-account">
          <div class="title"></div>
          <el-row :gutter="24">
            <el-col class="input-row" :sm="24">
              <el-col :span="8"> <div class="text">Current Password</div> </el-col>
              <el-col :span="16">
                <el-input show-password v-model="form.CurrentPassword" placeholder="..."></el-input>
              </el-col>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-row" :sm="24">
              <el-col :span="8"> <div class="text">New Password</div> </el-col>
              <el-col :span="16">
                <el-input v-model="form.NewPassword" placeholder="..."></el-input>
              </el-col>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-row" :sm="24">
              <el-col :span="8"> <div class="text">Confirm New Password</div> </el-col>
              <el-col :span="16">
                <el-input v-model="form.NewPasswordRetry" placeholder="..."></el-input>
              </el-col>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="buttons-group">
            <el-button class="btn" type="primary" @click="changePassword"><i class="icon-floppy-disk-solid"></i>Save</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {
   async changePassword() {
      var payload = {
        CurrentPassword: null,
        NewPassword: null,
        NewPasswordRetry: null,
      };
      var res = await this.$client.post("/Account/ChangePassword", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        this.getProject();
      } 
    },
    develop() {
      this.$message.info("Bu özellik geliştirme aşamasındadır...");
      return;
    },
  },
};
</script>
